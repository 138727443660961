

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL10PostLabPart10',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: null,
      },
      questions: [
        {
          text: 'Looking at the answers to the above questions, how much faith would you place in your conclusion for this experiment? If you were to REALLY have to testify in a court trial and your recommendations could potentially get someone arrested and imprisoned, what would you want to change or improve about this experimental scenario?',
          inputName: 'part1',
        },
      ],
    };
  },
};
