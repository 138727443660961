









import {defineComponent} from '@vue/composition-api';
import {UseAiGradingQuotes} from '@/loading/composables/UseAiGradingQuotes';
import {inject} from '@/container';

export default defineComponent({
  name: 'AiGradingQuote',
  setup() {
    return inject(UseAiGradingQuotes);
  },
});
