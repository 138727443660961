import {makeDependency} from '@/container';
import {QUOTES} from '@/loading/utils/aiQuotes';
import {computed} from '@vue/composition-api';

export const UseAiGradingQuotes = makeDependency(selectQuote);

function selectQuote(index?: number) {
  const quote = QUOTES[index ?? Math.floor(Math.random() * QUOTES.length)];
  const textTitle = computed(() => quote.textTitle);
  const textMessage = computed(() => quote.textMessage);

  return {textTitle, textMessage};
}
