export interface AiQuoteObject {
  textTitle: string;
  textMessage: string;
}

export const QUOTES: AiQuoteObject[] = [
  {
    textTitle: 'Submission Evaluation in Progress',
    textMessage: 'Your response is currently being reviewed.',
  },
  {
    textTitle: 'Scoring Your Response',
    textMessage: 'Please hold while we assess your work.',
  },
  {
    textTitle: 'Assessment Underway',
    textMessage: "We're reviewing your submission for accuracy.",
  },
  {
    textTitle: 'Reviewing Your Effort',
    textMessage: 'Our system is hard at work scoring your submission.',
  },
  {
    textTitle: 'Processing Your Submission',
    textMessage: "We're grading your work for correctness.",
  },
  {
    textTitle: 'Your Answer Under Review',
    textMessage: "We're carefully scoring your submission.",
  },
  {
    textTitle: 'Assessment in Action',
    textMessage: 'Your submission is being evaluated.',
  },
];
