











import {PropType} from '@vue/composition-api';
import AiGradingQuote from '@/loading/components/AiGradingQuote.vue';

export default {
  name: 'AiLoadingOverlay',
  components: {
    AiGradingQuote,
  },
  props: {
    message: {
      type: String as PropType<string>,
      default: null,
    },
  },
};
